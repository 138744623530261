// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

// document.addEventListener("DOMContentLoaded", async () => {
//   const { publishableKey } = await fetch("/config").then((r) => r.json());
//   alert("publishableKey >>>>" + publishableKey);
//   const stripePromise = loadStripe(publishableKey);

//   ReactDOM.render(
//     <React.StrictMode>
//       <Elements stripe={stripePromise}>
//         <App />
//       </Elements>
//     </React.StrictMode>,
//     document.getElementById("root")
//   );
// });

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { defineCustomElements } from '@ionic/pwa-elements/loader';
// defineCustomElements(window);

document.addEventListener("DOMContentLoaded", async () => {
  // const { publishableKey } = await axios(
  //   "https://stripe-api.tividad.com/config"
  // ).then((r) => r.data);

  //// const { publishableKey } = await axios.post("/config").then((r) => r.data);
  const { publishableKey } = await axios
    // .get("https://stripe-api.tividad.com/config")
    .post("https://ecommerce-api.tividad.com/api/v1/stripe/config")
    .then((r) => r.data);

  // const { publishableKey } = await fetch("/config").then((r) => r.json());
  // alert("publishableKey >>>>" + publishableKey);
 
  const stripePromise = await loadStripe(publishableKey);
  console.log("publishableKey >>>>", publishableKey, stripePromise);

  ReactDOM.render(
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
