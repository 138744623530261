import ApplePay from "./ApplePay";
// import GooglePay from "./GooglePay";
import Card from "./Card";
import "./App.css";
import CheckOut from "./CheckOut";

function App() {
  return (
    <>
      {/* <ApplePay /> */}
      {/* <GooglePay /> */}
      {/* <Card /> */}
      <CheckOut/>
    </>
  );
}

export default App;
