import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import StatusMessages, { useMessages } from "./StatusMessages";
import axios from "axios";
import ApplePay from "./ApplePay";
import GooglePay from "./GooglePay";

const customerId = "cus_Lur6fcjmuLDs3X";
const country = "AE";

const CheckOut: React.FC<any> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();

  const [items, setItems] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [currency, setCurrencty] = useState<string>("aed");
  const handleSubmit = async (e: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      addMessage("Stripe.js has not yet loaded.");
      return;
    }

    const { error: backendError, clientSecret } = await axios
      .post(
        "https://api.storein.net/api/v1/stripe/create-invoice-intent",
        // "https://ecommerce-api.tividad.com/api/v1/stripe/create-invoice-intent",
        // "http://localhost:8000/api/v1/stripe/create-invoice-intent",
        {
          customerId,
          paymentMethodType: "card",
          currency,
          items,
        }
      )
      .then((r: any) => r.data);

    if (backendError) {
      addMessage(backendError.message);
      return;
    }

    addMessage("Client secret returned");

    const card: any = elements.getElement(CardElement);
    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            name: "Auric Gavin",
            email: "auric.gavin@tividad.com",
          },
        },
      });

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      addMessage(stripeError.message);
      return;
    }

    // Show a success message to your customer
    // There's a risk of the customer closing the window before callback
    // execution. Set up a webhook or plugin to listen for the
    // payment_intent.succeeded event that handles any business critical
    // post-payment actions.
    addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(
        "https://api.storein.net/api/v1/products/fetch-all"
        // "https://ecommerce-api.tividad.com/api/v1/products/fetch-all",
        // "http://localhost:8000/api/v1/products/fetch-all"
      );

      let products: any = [];

      if (result.data.products) {
        result.data.products.map(
          (
            {
              productCode,
              productName,
              price_id,
              currencyCode,
              unitPrice,
            }: any,
            index: number
          ) => {
            products = [
              ...products,
              {
                productCode,
                name: productName,
                price_id,
                currency: currencyCode,
                price: unitPrice,
                quantity: 1,
              },
            ];
          }
        );
      }

      console.log("prods >>>", products);
      const sum = products.reduce((accumulator: any, object: any) => {
        return accumulator + object.price;
      }, 0);

      setTotalAmount(sum);

      setItems(products);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // const { error: backendError, clientSecret } = await axios
    //   .post(
    //     // "https://stripe-api.tividad.com/create-payment-intent",
    //     "https://ecommerce-api.tividad.com/api/v1/stripe/create-invoice-intent",
    //     // "https://ecommerce-api.tividad.com/api/v1/stripe/create-payment-intent",
    //     {
    //       paymentMethodType: "card",
    //       currency: "aed",
    //     }
    //   )
    //   .then((r: any) => r.data);
  }, [setItems]);

  return (
    <>
      <h1>Check out</h1>

      <table id="products">
        <thead>
          <tr>
            <th>productCode</th>
            <th> productName</th>
            <th> price_id</th>
            <th> quantity</th>
            <th> unitPrice</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.length > 0 &&
            items.map(
              (
                { productCode, name, price_id, currency, price }: any,
                index: number
              ) => {
                return (
                  <tr key={index}>
                    <td>{productCode}</td>
                    <td> {name}</td>
                    <td> {price_id}</td>
                    <td> 1 </td>
                    <td>
                      {currency}
                      {price}
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>

      <br />
      <br />

      {totalAmount && (
        <ApplePay
          // customerId={customerId}
          // country={country}
          // items={items}
          // totalAmount={totalAmount}
          // currency={currency}
        />
      )}
{/*       
      {totalAmount && (
        <GooglePay
          customerId={customerId}
          country={country}
          items={items}
          totalAmount={totalAmount}
          currency={currency}
        />
      )} */}

      <form id="payment-form" onSubmit={handleSubmit}>
        <label htmlFor="card">Card</label>
        <CardElement id="card" />

        <button type="submit">
          Pay Total Amount of {currency.toUpperCase()}
          {totalAmount}
        </button>
      </form>
      <StatusMessages messages={messages} />

      <p>
        {" "}
        <a href="https://youtu.be/IhvtIbfDZJI" target="_blank" rel="noreferrer">
          Watch a demo walkthrough
        </a>{" "}
      </p>
    </>
  );
};

export default CheckOut;
